import React, { useState } from 'react'

const Livechat = () => {
  const [message, setMessage] = useState([
    {message: "Hi! What can I help you with?"}
  ])
  const [msgText, setMsgText] = useState("")

  const onClickSend = () => {
    var msg = message
    msg.push({"message": msgText, "user": true })
    setMessage(msg)
    setMsgText("")

    console.log(message)
  }
  return (
    <div className="bg-[#f2f2f2] h-dvh p-5">
      <p className = "lg:text-4xl text-lg	font-semibold text-center ">Ask me anything about Company Registration India</p>
      <p className = "text-lg	mt-6 text-center">Note: I am not a Approved Robot to consult, you should check with Professionals such as CAs, CS, or Government Officials always.</p>

    <div className="flex lg:flex-row flex-col ">
        <div className = "flex lg:w-full flex-col items-center justify-center">
            <div className="h-[550px] bg-white rounded lg:w-3/5 w-full p-10 flex flex-col overflow-scroll	">
                { message && message.length > 0 && message.map((msg)=>{
                  return(
                    <div>
                      { msg.user ? 
                      <div className="flex items-end justify-end mt-5"><span className = "bg-[#ECECEC] p-2 rounded text-end">{msg.message}</span></div> : 
                      <span className = "bg-[#ECECEC] p-2 rounded mt-2">{msg.message}</span>
                      }
                    </div>
                  )
                })

                }
            </div>
            <div className="h-1/6 w-3/5 flex flex-row items-end justify-center">
              <input
                  className="w-full py-2 px-6 text-black placeholder-grey font-medium text-base shadow-md leading-tight rounded h-12"
                  type="text"
                  name='msgText'
                  value={msgText}
                  onChange={(e) => {
                    setMsgText(e.target.value)
                  }}
                  placeholder="Message..." 
              />
               <button
                className="bg-[#A9A9A9] text-white font-semibold py-2 px-5 mt-5 rounded-md h-12 ml-5"
                  // type="submit"
                  name="Send"
                  onClick={onClickSend}
                >
                  Send
                </button>
            </div>
            <div className="text-[#A9A9A9] text-center mt-5 lg:text-xl text-base lg:visible invisible">Nithya Gokul Associates</div>
        </div>

        <div className = "lg:w-2/5 w-full flex items-center justify-center bg-[#f2f2f2]">
            <div className=" p-10 bg-[#DCDCDC] shadow-2xl w-full w-full  rounded ">
               <p className = "lg:text-4xl text-md	mt-6 font-semibold text-center">CONTACT FORM</p>
               <form className="form-box" onSubmit={()=>{}}>
                <div className="flex flex-row mt-10">
                 <label htmlFor="city" className="w-1/5">Name </label>
                 <label htmlFor="phone" className="w-1/5">:</label>
                 <input type="text" id="city" name="city" className="w-3/5 p-2 rounded"  />
                </div>
                <div className="flex flex-row mt-10">
                 <label htmlFor="city" className="w-1/5">Email </label>
                 <label htmlFor="phone" className="w-1/5">:</label>
                 <input type="text" id="city" name="city" className="w-3/5 p-2 rounded"  />
                </div>
                <div className="flex flex-row mt-10">
                 <label htmlFor="city" className="w-1/5">Phone </label>
                 <label htmlFor="phone" className="w-1/5">:</label>
                 <input type="text" id="city" name="city" className="w-3/5 p-2 rounded"  />
                </div>
                <div className="flex flex-row mt-10">
                 <label htmlFor="city" className="w-1/5">City </label>
                 <label htmlFor="phone" className="w-1/5">:</label>
                 <input type="text" id="city" name="city" className="w-3/5 p-2 rounded"  />
                </div>
                <div className="flex flex-row mt-10">
                 <label htmlFor="city" className="w-1/5">State </label>
                 <label htmlFor="phone" className="w-1/5">:</label>
                 <input type="text" id="city" name="city" className="w-3/5 p-2 rounded"  />
                </div>
                <div className="flex flex-row mt-10">
                 <label htmlFor="city" className="w-1/5">Comment </label>
                 <label htmlFor="phone" className="w-1/5">:</label>
                 <textarea 
                maxLength={500} 
                className='w-3/5 py-2 px-6 text-black placeholder-black font-medium text-base shadow-md leading-tight' 
                name="message" 
                id="" 
                cols="30" 
                rows="5" 
                placeholder='YOUR MESSAGE' required>
              </textarea>
                </div>
                <div className="flex items-center justify-center">
                <button
                  className="w-[140px] bg-[#A9A9A9] text-white font-semibold py-2 px-5 mt-5 rounded-md"
                  type="submit"
                  name="Submit"
                >
                  Submit
                </button>
                </div>
               </form>
            </div>
        </div>

       
    </div>
    <div className="text-[#A9A9A9] text-center p-5 lg:text-xl text-base lg:invisible visible bg-[#f2f2f2]">Nithya Gokul Associates</div>

    </div>
  )
}
export default Livechat;
